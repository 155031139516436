<template>
  <div id="app">
    <FormField/>
  </div>
</template>

<script>
import FormField from './components/FormField.vue'

export default {
  name: 'App',
  components: {
    FormField
  }
}
</script>
