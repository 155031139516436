<template>
  <div class="p-2">
      <div class="bg-white shadow max-w-max pr-4">
        <div class="flex flex-wrap space-x-4 text-center">
          <div class="grid grid-rows-2 bg-blue-100 w-full sm:w-72 md:w-72 lg:w-90 h-36 p-1">
            <div> 
              <h1 class="text-xl opacity-60 p-4">Company name checker</h1>
            </div>
            <div class="font-semibold">
              <p v-if="message !== ''">{{ message }}</p>
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-start py-4">
            <div class="flex">
              <input
                class="shadow border w-full py-2 px-3 text-gray-700"
                autofocus
                v-model="companyName"
                type="text"
                placeholder="Enter company name"
                v-on:keyup.enter="submit" 
              />  
            </div>
            <div class="flex pl-4 pr-4 sm:pr-6 md:pr-10">
              PTE. LTD.
            </div>
            <div class="flex items-end justify-end py-2 sm:py-4 md:py-4">
              <button class="bg-blue-600 p-2 px-12 text-white text-center text-lg font-bold rounded-sm"
              @click="submit()">
                Check
            </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      companyName: '',
      message: '',
      errors: []
    }
  },

  methods: {
     submit() {
       axios.get(process.env.VUE_APP_API_URL, { params: { name : this.companyName } })
        .then(({ data }) => {
          //var result = data.shortDescription.split(".");
          this.message = data.shortDescription; 
        })
        .catch(e => {
          this.errors.push(e)
        })
     }
  },
}
</script>
